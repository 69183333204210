.vitrine_carousel{
    max-width: 99rem;
    display:block;

}

.carousel{
    padding: 1rem;
}
.caroselimage{
    max-width: 99rem;
    width: auto;
    height: auto;
    object-fit: contain;

}

.in-container {
    padding: 0px 10px 0px 10px;
}

.product-not-found {
    color: var(--background-thema);
}

.product-not-found__description {
    font-size: 16px;
}

.product-not-found__description li {
    line-height: 30px;
}

.container-imagem {
    height: 200px;
}
.vitrine-imagem {
    width: var(--width-vitrine-imagem);
    height: var(--height-vitrine-imagem);
}

.cursor-pointer {
    cursor: pointer;
}

.img-preview {
    width: 200px;
    height: auto;
    border-radius: 50px;
}

.text-naoencontrado-sistema {
    color: var(--color-header-desktop) !important;
}

.nav-header--tertiary {
    background-color: var(--background-thema);
}

/* Geral */
.main-layout {
    background: var(--background-default) !important;
    height: unset;
    padding-top: 101px
}

.search-input__erase-button {
    top: 43%;
    position: absolute !important;
}

.footer {
    display: none;
}

.responsive-header,
.delivery-input,
.area-container {
    background-color: var(--background-header-desktop);
    color: var(--color-primaria-thema);
}

.area-cart {
    background-color: var(--background-area-cart) !important;
}

.vitrine-container-LISTA,
.vitrine-container-GRADE,
.vitrine-container-FAVORITO {
    padding: 10px 15px;
}

.header-cart--has-items,
.floating-box__button,
.floating-box__button .icon-search svg {
    color: var(--color-secundaria-header) !important;
}

.floating-box__button:hover .icon-search svg,
.floating-box__button .icon-search svg:hover {
    margin: 8px 20px 0 0;
    color: var(--color-secundaria-header-hover) !important;
    fill:  var(--color-secundaria-header-hover) !important;
}

.backgroud-destaque {
    background-color: var(--background-button);
    color: var(--color-button);
}

.fa .fa-check {
    color: green;
}

.backgroud-destaque > .fa .fa-check {
    color: #ffffff !important;
}

.responsive-header {
    z-index: 1000;
}

.responsive-header,
.content-container {
    width: 100%;
    /* padding: 10px; */
}

.backgroundContent-preload {
    background-color: #ffffff !important;
    height: 100%;
}

.btnAdd {
    display: block !important;
    font-size: 15px !important;
}

.btn-primary {
    color: #ffffff !important;
}

.btn-icon.btn-icon--transparent svg {
    color: var(--background-button);
}

.btn--preload {
    background-color: #ffffff !important;
    border: 1px solid #dcdcdc !important;
}

/* Menu Top */
.icon-search-mobile {
    color: var(--color-header-mobile) !important;
    font-size: 20px !important;
}

.responsive-header__search {
    height: 8vh;
}

.responsive-header__search .search-input__field-wrapper > button {
    right: 0px;
    top: 23px;
    bottom: 0px;
}

.responsive-header__search .search-input__field-wrapper > button.btn-icon .icon-search {
    padding: 2px -1px 2px 0px;
}

.responsive-header__logo {
    flex: 0 0 10.666667%;
    max-width: 10.666667%;
}

.responsive-header__address {
    flex: 0 0 39.333333%;
    max-width: 39.333333%;
}

.responsive-header {
    height: 80px;
    position: fixed;
}

.header-modal-title {
    margin: -9px;
}

.mobile h1,
.header-modal-title h1 {
    margin: 17px 0px;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
}

.area-container {
    background-color: var(--background-area-container);
    height: 100%;
}

.logoTipo-desktop {
    width: var(--width-logoEmpresaMenu-desktop);
}

.logoTipo-mobile {
    width: var(--width-logoEmpresaMenu-mobile);
    margin-left: var(--ml-EmpresaMenu-mobile);
    margin-top: var(--mt-EmpresaMenu-mobile);
}

.logoTipo-mobile {
    display: var(--display-logoEmpresaMenu-mobile);
}

.mobile {
    display: none;
}

.__title {
    background-color: var(--background-thema);
}

.__title.full-content {
    padding: 0px;
}

.__title .icon-arrow,
.__title h1,
.__title > * {
    color: var(--color-header-mobile);
}

.icon-search-font {
    font-size: 26px;
    color: #000000;
    font-weight: bold;
}

.icon-search-close {
	width: 57px;
	height: 57px;
	border-radius: 50%;
	overflow: hidden;
	float: left;
    background-color: #A6A29F;
    color: #ffffff;
}

.search-input__field {
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    color: var(--color-texto);
    background-color: #f6f5f5;
    border: '0px'
}

.btn-icon .icon-search {
    padding: 2px 1px 2px 0px;
}

.delivery-input-wrapper__title{
    font-size: 16px;
    line-height: 18px;
    color: var(--color-primaria-header);
    font-weight: 400;
}

.icon-home-font {
    font-size: 21px;
    line-height: 23px;
    color: var(--color-secundaria-header) !important;
    font-weight: bold;
}

.header-icon-font.icon-menu:hover{
    color: var(--color-secundaria-header-hover) !important;
    margin: 8px 20px 0 0;
}

.delivery-input__address {
    font-size: 16px;
    line-height: 25px;
    color: var(--color-secundaria-header) !important;
    font-weight: 300;
}

.icon-search-seta-font {
    font-size: 32px;
    line-height: 28px;
    color: var(--color-primaria-thema);
    font-weight: bold;
}


.header-cart__icon {
    margin: 0px 20px 0 0;
}

.responsive-header__button .icon-search {
    width: 25px;
    height: 28px;
    margin: 8px 5px 0 0;
}

.header-cart__ammount {
    width: 24px;
    height: 24px;
    font-size: 10px;
    line-height: 11px;
    font-weight: bold;
    color: var(--color-badge-desktop);
    background-color: var(--background-badge-desktop);
    border-color: var(--color-badge-border);
    margin: 10px 4px;
}

.header-label-font {
    font-size: 17px;
    line-height: 18px;
    font-weight: 400;
}

.header-icon-font {
    font-size: 30px;
    line-height: 30px;
    color: var(--color-secundaria-header) !important;
    font-weight: bold;
}

.header-cart__ammount,
.header-cart__label--wide,
.icon-menu.pe-7s-cart,
.icon-menu.pe-7s-shopbag {
    color: var(--color-icon-header-desktop);
}

.header-cart__ammount {
    color: var(--cor-lbl-icon-menu-top);
}

/* PerfilButton - Menu Top*/
.user-menu-items {
    background-color: #ffffff;
    /* height: 42em; */
    overflow-y: auto;
}

.user-menu-items__name {
    font-size: 25px;
    line-height: 31px;
    font-weight: 500;
    color: #000000;
}

.btn-navigation:hover .icon-submenu-font,
.btn-navigation:hover .btn-navigation__label {
    color: var(--color-link-acao) !important;
}

.btn-navigation__label {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
}

.btn-navigation__icon svg {
    color: #000000;
}

.icon-submenu-font {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    color: #000000;
}

.menu .icon-search svg {
    font-size: 16px;
}

/* Menu Bottom */
.tab-bar {
    box-shadow: inset 0 1px 0px #dcdcdc;
    padding-top: 1px;
    display: flex;
    z-index: 30;
    background-color: var(--background-footer-mobile);
}

.tab-bar-item.active {
    border-bottom: 3px solid var(--color-icon-footer-mobile-active);
    font-weight: bold;
}

.tab-bar-item.active .icon-marmita,
.tab-bar-item.active .tab-bar-item__title {
    color: var(--color-icon-footer-mobile-active);
    font-weight: bold;
}

.tab-bar-item .icon-marmita {
    color: var(--color-icon-footer-mobile);
}

.tab-bar .tab-bar-item__title {
    font-size: 12px;
    line-height: 14px;
    color: var(--color-icon-footer-mobile);
    font-weight: 300;
}

.icon-marmita {
    font-size: 25px;
    line-height: 29px;
    color: #3f3e3e;
    font-weight: bold;
}

/* Carrinho */
#sideShoppingBag {
    position: fixed;
    right: 0;
}

.area-cart .area-cart-items-list {
    max-height: unset;
    overflow: unset;
}

.area-cart-header__pretitle {
    font-size: 22px;
    line-height: 23px;
    font-weight: 400;
}

.area-cart-footer__info-subtotal,
.area-cart-footer__info-taxa,
.area-cart-footer__info-description,
.area-cart-item__description,
.payment-info__title,
.payment-info__item-title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: var(--cor-area-cart-item__description-lable);
}

.area-cart-footer__info-taxa-complemento {
    font-size: 12px;
    font-weight: 400;
    color: #d12525;
}

.payment-info__title,
.area-cart-footer__info-description {
    font-weight: 600;
}

.payment-info__title {
    font-size: 22px;
    padding: 0px 0px 20px 0px;
}

.payment-info__item-detail {
    text-transform: none;
}

.payment-info__item-detail-payment {
    font-size: 14px;
    line-height: 7px;
}

.product-action__counter__button.btn--default:hover:enabled,
.area-cart-footer__button.btn.btn--default:hover:enabled {
    background-color: var(--background-button-hover);
    color: var(--color-button-hover);
}

.product-action__add-button {
    width: 21vw;
}

.product-action__counter__button.btn--default,
.area-cart-footer__button.btn.btn--default {
    background-color: var(--background-button);
    color: var(--color-button);
}

.payment-methods-modal__method-button.preload {
    text-align: center;
    display: inherit !important;
}

.btn__label-light,
.payment-info__action {
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
    color: var(--color-link-acao);
}

.btn--link.btn--gray .btn__label-light {
    color: var(--color-link-remover);
}

.area-cart-item__button {
    width: 95px !important;
    text-align: left;
    padding: 0px;
}

.area-cart-footer__info > * {
    font-size: 16px !important;
    line-height: 22px !important;
}

.area-cart-footer__info > *:last-child {
    font-weight: bold;
}

.area-cart-footer__button-wrapper .btn__label-light {
    color: var(--cor-lbl-icon-menu-top) !important;
}

.area-cart-footer__button .btn__label {
    justify-content: center !important;
}

.area-container .order-column {
    z-index: 999;
}

.area-container .order-column .area-cart {
    height: 89vh !important;
    /* height: 78vh !important;     */
    overflow-y: auto;
}

.area-cart-content {
    height: 68vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.area-cart-content-perfil{
    height: 85vh !important;
}
.a-tamanho-perfil{
    height: 50px;
}

.area-cart-footer a {
    padding: 13px 12px;
}

.area-cart__empty--large {
    font-size: 20px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
    color: var(--color-primaria-thema) !important;
}

.area-cart__empty--small {
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    color: #717171 !important;
}

.area-card-empty-font {
    font-size: 82px !important;
    line-height: 84px !important;
    font-weight: 400 !important;
    color: #717171 !important;
}

/* Carrinho mobile */
.cart-bar__title,
.cart-bar__amount,
.cart-bar__cost,
.icon-badge {
    font-size: 10px;
    font-weight: 600;
    line-height: 27px;
}

.cart-bar__title,
.cart-bar__cost {
    font-size: 15px;
}

.cart-bar__amount,
.icon-badge {
    height: 16px;
    min-width: 16px;
    line-height: 16px;
}

.icon-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-badge-mobile);
    color: var(--color-badge-mobile);
    border: 2px solid var(--background-badge-mobile);
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    font-family: "SulSans", Helvetica, sans-serif;
}

.cart-bar__add {
    line-height: 3px;
}

/* Vitrine */
.vitrine {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 104px;
    padding: 0px 80px 0px 0px;
}

.vitrine.preload {
    padding: 17% 0px 0px 0px;
    margin-bottom: 0px;
}

.mini-content .vitrine.preload {
    padding: 11em 0px 0px 0px;
    margin-bottom: 0px;
}

.vitrine > .col-md-3,
.vitrine > .col-sm-3,
.vitrine > .col-lg-3,
.vitrine > .col-xs-3 {
    padding: 5px !important;
}

.text-preco {
    font-size: 22px;
    line-height: 18px;
    color: #333333;
    font-weight: 700;
}

.text-preco-desconto {
    font-size: 18px;
    line-height: 18px;
    color: #333333;
    font-weight: 300;
    text-decoration:line-through;
    font-style: oblique;
}

.text-porcentagem {
    font-size: 14px;
    line-height: 20px;
    color: #00e650;
    font-weight: 400;
}

.media-heading-LISTA,
.media-heading-GRADE {
    color: var(--color-texto);
    font-size: 15px;
    font-weight: bold;
    height: 62px;
}

.media-heading-GRADE {
    font-weight: normal;
    height: 45px;
    max-height: 45px;
}


/* Modal Vitrine Detail */
.product-content__wrapper-container {
    display: flex;
    flex-direction: column;
    padding: 0 0 16px;
    margin: 0 0 16px 0;
    background: #ffffff;
}

.product-content__title {
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
    color: var(--color-primaria-thema);
    height: 25px; 
     

}
.product-content__titlemobile{
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    color: var(--color-primaria-thema);
    margin-bottom: 2rem;
    height: auto;


    

}
.product-content__title__description{
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    color: var(--color-primaria-thema);
    margin-bottom: 2rem;  
    height: 1.50rem;
    overflow-y: auto;

}


.product-content__description{
    font-size: 15px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 1rem;
    color: var(--color-primaria-thema);
    height: 55px;    
    height: 5rem;
    overflow-y: auto;

}


.product-content__details {
    font-size: 16px;
    line-height: 34px;
    font-weight: 300;
    color: #717171;
}

.toggle > span.texto {
    display: none;
}

.product-content__container-price {
    font-size: 21px;
    line-height: 30px;
    font-weight: 400;
    color: var(--color-primaria-thema);
}

.product-content__container-price > span > .row {
    margin-right: 0;
    margin-left: 0;
}

.product-content__container-price > *:first-child {
    font-size: 16px;
    color: #999999;
}

.vitrine-content {
    padding: 52px;
    background-color: #ffffff;
}

.product-tooltip {
    border-top: 2px solid #f5f0eb;
    padding: 20px 0px 5px 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100vw;
}

.product-action__add-wrapper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.product-action__counter {
    display: inline-flex;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
}

.product-action__counter__label {
    /* width: 130px; */
    width: 100%;
}

.product-action__counter__button {
    width: 100%;
    margin: 0px;
}

.excludeButton{
    width: 100%;
    margin: 0px;
    background-color: #000000;
}

.swal-wide{
    font-size: 2rem !important;
    padding: 2rem;
}

.btn .btn__label2,
.btn .btn__label {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: #ffffff;
}

.product-tooltip .btn {
    margin: 0px
}

li.showcase-product__thumbs {
    width: 100%;
    height: 75px;
    padding: 0;
    border-bottom: 1px solid #e6e4e6
}

.showcase-product__thumb--active {
    border: 2px solid #0083CA;
    border-bottom: 2px solid #0083CA !important;
}

.showcase-product__thumb-layer-number {
    font-size: 18px;
    line-height: 24px;
    color: #404040;
}

/* Input Number */
.number-counter {
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border: 1px solid #dcdcdc;
}

.number-counter .btn-icon {
    margin-left: -10px;
    margin-right: -10px;
    font-size: 12px;
}

.btn-icon.btn-icon--transparent {
    background: inherit;
    color: green;
}

.btn-icon {
    padding: 0;
    width: 40px;
    height: 40px;
    background: green;
    color: #f2d298;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 3px 5px;
    border-radius: 100%;
    outline: none;
    transition: 100ms;
    position: relative;
    overflow: hidden;
    cursor: default;
}

.number-counter__value {
    display: inline-block;
    height: 30px;
    min-width: 35px;
    line-height: 34px;
    text-align: center;
    font-size: 2.125rem;
    font-weight: 500;
    margin: 3px 0;
}

.ofertas > * {
    color: var(--color-secundaria-header) !important;
}

/* Media Query */
@media only screen and (min-width: 960px) {
    .vitrine_carousel{
        max-width: auto;
        display:block;
        
    }
    
    .carousel{
        padding: 1rem;
    }


    .product-action__update-wrapper,
    .product-action__add-wrapper {
        justify-content: flex-end;
    }

    .product-action__counter {
        margin-right: 15px;
    }

    .search-input__icon-search {
        width: 26px;
        height: 26px;
        left: 20px;
        top: 50%;
        position: relative;        
        display: contents;
    }

    .search-input__field-wrapper {
        padding: 0px 10px;
    }

    .search-input__field {
        padding-left: 10px
    }
}

@media (max-width: 768px) {
    .vitrine {
        padding: 0;        
        width: 100%;
    }
    .caroselimage{
width: 100%;
max-width: 100%;
  }

  .swal-wide{
    font-size: 1rem !important;
    padding: 1rem;
}

    .responsive-header--static {
        position: fixed;
    }

    .main-layout {
        padding: 50px 0 0;
        background-color: var(--background-default) !important;
    }

    .footer {
        display: block;
        background: var(--background-area-container);
        margin-top: 0px;
    }

    .vitrine-container-LISTA,
    .vitrine-container-GRADE,
    .vitrine-container-FAVORITO {
        margin: 0;
        border-radius: 0px;
        border: 0px;
        box-shadow: inset 0 -1px 0 #dcdcdc;
        background-color: #ffffff !important;
    }

    .vitrine.preload {
        padding-top: 30%
    }

    .btn-icon.btn-icon--transparent {
        font-size: 10px;
    }

    .btn .btn__label {
        font-size: 14px;
    }

    .area-cart-footer {
        width: 100%;
    }

    .payment-action {
        z-index: 101;
    }

    .product-content__description{
        font-size: 15px;
        line-height: 14px;
        font-weight: 300;
        margin-bottom: 1rem;
        color: var(--color-primaria-thema);
        height: 55px;    
        height: 4.5rem;
        overflow-y: auto;
    
    }

}

@media (min-width: 768px) {
    .vitrine-container-LISTA,
    .vitrine-container-GRADE,
    .vitrine-container-FAVORITO {
        height: 156px;
        border: 1px solid #e6e4e6;
        border-radius: 5px;
        margin: 5px;
        background-color: #ffffff !important;
    }

    .vitrine-container-GRADE,
    .vitrine-container-FAVORITO {
        height: 485px;
    }

    .vitrine-container-GRADE a,
    .vitrine-container-FAVORITO a {
        width: 100px;
    }


}
@media only screen and (min-width: 1600px) and (max-width: 2700px) {
    .navMenuDesktop{
        margin-top: -2.0rem;
    }

}

@media only screen and (min-width: 1px) and (max-width: 1247px) {


    .mobile {
        display: block;
    }

    .responsive-header {
        height: unset !important;
    }

    .main-layout {
        padding-top: 55px;
    }

    .desktop {
        display: none;
    }

    .responsive-header {
        display: grid;
        height: 49px;
    }
}
@media only screen and (min-width: 1750px) {
    .caroselimage{
        max-width: 100%;
        width: 100%;
    
    }
    .vitrine_carousel{
        max-width: 83% !important;
        width: auto;
        height: auto;
        display:block;
    
    }
    .navMenuDesktop{
        margin-top: -1.5rem;
    }

    
}
@media only screen and (min-width: 320px) and (max-width: 1023px) {
    .responsive-header__search .search-input__field-wrapper > button {
        top: 0px
    }
}

@media only screen and (min-width: 1024px) {
    .responsive-header__search .search-input__field-wrapper > button {
        top: 23px
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
    .vitrine {
        padding: 0px 125px 0px 14px;
    }

}

@media only screen and (min-width: 1247px) {
    .tab-bar {
      display: none;
    }

    .responsive-header__wrapper-links > * {
        margin: 0 0 0 9px;
    }


}

@media screen and (min-width:650px) {
    .cardMenus {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }
    
}