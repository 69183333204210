/* lista */
.politica ul {
    padding-left: 30px;
}

.politica a:not([href]) {
    color: #337ab7 !important;   
    height: 50px;
    text-decoration: none;
}
  
.politica a:not([href]):hover {
    color: #0056b3 !important;
    text-decoration: none;
}