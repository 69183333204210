.context-card__maximized {
    background-color: var(--background-thema);
}

.cart-bar__amount {
    background-color: var(--background-bagde-cart-mobile);
    color: var(--color-bagde-cart-mobile);
    border-color: var(--background-bagde-cart-mobile);
}

.shopping-success__title {
    background-color: var(--background-thema);
    color: var(--color-thema);
}

.payment-methods-modal__method-button span {
    font-size: 16px !important;
    line-height: 22px !important;
}

.context-card__minimized .btn {
    border-radius: 0px !important;
}

.context-card__minimized {
    bottom: 49px;
    height: 51px;
    background-color: var(--background-cart-mobile);
}

.context-card.context-card--visible {
    visibility: hidden;
}

.checkout__container-scroll {
    background-color: var(--background-default);
    position: relative;
}

.checkout__delivery {
    background-color: var(--background-thema);
}

.checkout__order {
    box-shadow: none;
}

.context-card .context-card__btn-open {
    height: 100%;
    background-color: var(--background-cart-mobile);
    color: var(--color-cart-mobile);
}

.cart-bar {
    height: 41;
}

.cart-bar__container {
    height: 47px;
}

.icon-cart.pe-7s-cart,
.icon-cart.pe-7s-shopbag {
    color: var(--color-icon-cart) !important;
}

.header-icon-font.icon-cart {
    font-size: 25px;
    line-height: 15px;
    font-weight: 500;
}

.delivery-info__street-name {
    padding-left: 0px;
    color: var(--color-button);
}

.delivery-info .icon-marmita {
    color: var(--color-button);
    font-weight: 500;
    margin-right: 5px;
    font-size: 24px;
    line-height: 18px;
}

.nav-header__left-button .icon-marmita .header-icon-font,
.nav-header__left-button .icon-arrow .header-icon-font {    
    color: var(--color-button) !important;
}

.delivery-info__street-name,
.delivery-info__complement,
.delivery-info__change-address-button {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: var(--color-button);
    padding-left: 8px;
}

.delivery-info__complement {
    font-weight: 500;
}

.delivery-info {
    display: inherit;
    min-height: 40px;
}

.delivery-info > * > * {
    padding: 0;
}

.nav-header--with-padding {
    padding: 10px 12px;
}

.area-cart__empty .area-card-empty-icon {
    height: 0;
    margin: 0;
    padding-top: 80px;
}

.checkout__container-scroll .checkout > * {
    border-bottom: 0;
}

.area-cart__line_button {
    position: fixed;
    bottom: 0px;
    width: 25.4%;
    height: 68px;
    padding: 6px 13px;
    background-color: var(--background-area-cart);
    z-index: 1030;
}

.area-cart-footer__button-wrapper {
    padding: 12px 0px 0px;
}

.area-cart-footer__button {
    padding: 15px 0px;
}

.payment-info {
    padding: 7px 0;
}

.payment-info button.btn {
    padding: 6px 0;
}

.opcao-entrega-info__action {
    font-weight: bold !important;
    color: var(--color-link-acao) !important;
}

.__lista-endereco {
    padding: 0px 3px 3px 3px;
}

.container-address.__lista-endereco {
    height: 30em;    
}

.listaEnderecoContainer,
.listaEnderecoContainer.__lista-endereco {
    overflow-x: auto;
    min-height: 100px;
    max-height: 374px
}

.listaEnderecoContainer.__lista-endereco {
    min-height: 100px;    
}

.AddressNew {
    font-size: 22px;
}

.icon-addressNew {
    font-Size: 26px;
}

.address-cadastro {
    overflow-x: auto;
}

.container-address {
    padding: 0px 5px;
    height: 30vh;
    overflow-x: auto;
    overflow-y: auto;
}

.AddressBox {
    border: 1px solid #e6e4e6;
    border-radius: 5px;
    margin-top: 10px;

    display: flex;
    flex-direction: column;

    position: relative;
    display: -ms-flexbox;
    display: flex;
    font-weight: normal;
    height: auto;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0px;
    width: 100%;
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;
}

.AddressBox-padrao {
    border-color: var(--background-button);
}

.AddressBox-address {
    text-transform: capitalize !important;
    font-size: 14px;
}

.AddressBox a {
    color: #337ab7;
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    margin-right: 30px;
}

.AddressBox a:hover {
    color: #337ab7;
}

.AddressBox:hover {
    background-color: #F7F7F7;
}

.AddressBox-continue {
    background-color: var(--background-button);
    color: var(--color-button);
}

.AddressBox-Button {
    align-items: center;
    align-self: center;
    text-align: center;
}

.AddressBox-zipcode {
    margin-bottom: 10px;
}

.AddressBox-title {
    margin-bottom: 10px;
}

.containerActions {
    cursor: pointer;
    width: 100%;
    padding: 0 15px;
}

.containerButtons {
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    height: 22px;
}

/* .containerButtons > .AddressBoxButtonPadrao {
    color: red;
    border: 1px solid red;
    padding: 5px;
    border-radius: 5px;
    height: 31px;
    background: #fff;
} */

.containerCheckEndereco {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .containerCheckEndereco input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -79px;
    left: -50px;
    height: 25px;
    width: 25px;
  }
  
  /* On mouse-over, add a grey background color */
  /* .containerCheckEndereco:hover input ~ .checkmark {
    background-color: #ccc;
  } */
  
  /* When the checkbox is checked, add a blue background */
  /* .containerCheckEndereco input:checked ~ .checkmark {
    background-color: #2196F3;
  } */
  
  /* Create the checkmark/indicator (hidden when not checked) */
  /* .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  } */
  
  /* Show the checkmark when checked */
  /* .containerCheckEndereco input:checked ~ .checkmark:after {
  } */
  
  /* Style the checkmark/indicator */
  .containerCheckEndereco .checkmark {
    left: -20px;
    top: -58px;
    width: 20px;
    height: 50px;
    border: 100px solid green;
    border-width: 0px 10px 10px 0;
    transform: rotate(45deg);
  }

.area-cart__delivery,
.area-cart__warning {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color: #4d4d4d;
    padding: 30px 25px;
    text-align: justify;
}

.area-cart__delivery {
    padding: 10px 25px;
    margin: 5px 0px;
    background-color: #f6f5f5;
    height: auto;
}

.payment-info__action {
    font-size: 16px;
    justify-content: space-between;
}

.payment-info__action .fa-money-bill-wave {
    color: var(--color-link-acao);
    font-size: 22px;
}

.area-cart-header,
.area-cart__line {
    margin: 0 15px;
}

button.btn.btn--default.btn--white.btn--size-m.payment-methods-modal__method-button {
    display: flex ;
}

.payment-methods-modal__method-button {
    position: relative;
    display: block;
    font-weight: normal;
    height: 60px;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    font-size: 16px;
    text-align: left;
    text-transform: none;
    padding: 10px 20px;
    background-color: #ffffff;
}

.payment-methods-modal__method-div {
    display: block;
    border-bottom: 1px solid #dcdcdc;
}

.payment-methods-modal__method-div-div {
    height: 100px;
}

.payment-methods-modal__method-button-complemento-descricao {
    height: 10px;
}

.payment-methods-icon {
    font-size: 20px;
}

button.btn.btn--default.btn--white.btn--size-m.payment-info__button:hover,
button.btn.btn--default.btn--white.btn--size-m.payment-methods-modal__method-button:hover {
    background-color: inherit;
}

.product-peso__label {
    color: #800000;
    font-size: 12px
}

@media (max-width: 768px) {
    .context-card.context-card--visible {
        visibility: inherit;
    }

    .area-cart {
        width: 100vw;
    }

    .area-cart-footer {
        width: 100vw !important;
    }

    .area-cart__line_button {
        width: 100%;
    }

    .area-cart .area-cart-items-list {
        max-height: unset !important;
        overflow-y: unset !important;
    }

    .area-container .order-column .area-cart {
        height: inherit;
        overflow-y: unset;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .AddressBox {
        display: block;
    }

    .container-address {
        padding: 0;
        width: 100%;
    }

    .listaEnderecoContainer {
        max-height: 358px;
        min-height: 100px;
    }

    .listaEnderecoContainer.__lista-endereco {
        min-height: 100px;
        max-height: 312px;
    }

    .AddressNew {
        font-size: 16px;
    }

    .icon-addressNew {
        font-Size: 16px;
    }

    .address-cadastro {
        height: 69vh;
    }

    .cart-bar__amount {
        right: 7px !important;
    }

    .area-cart {
        width: 100vw;
    }

    .area-cart-footer {
        width: 100vw !important;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .AddressBox {
        display: block;
    }

    .container-address {
        width: 100%;
        padding: 0;
    }

    .listaEnderecoContainer {
        max-height: 458px;
        min-height: 100px;

    }

    .listaEnderecoContainer.__lista-endereco {
        min-height: 100px;
        max-height: 410px;
    }

    .AddressNew {
        font-size: 16px;
    }

    .icon-addressNew {
        font-Size: 16px;
    }

    .address-cadastro {
        height: 73vh;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .AddressBox {
        display: block;
    }

    .container-address {
        width: 100%;
        padding: 0;
    }

    .listaEnderecoContainer {
        max-height: 363px;
        min-height: 100px;
    }

    .listaEnderecoContainer.__lista-endereco {
        min-height: 100px;
        max-height: 312px;
    }

    .AddressNew {
        font-size: 16px;
    }

    .icon-addressNew {
        font-Size: 16px;
    }

    .address-cadastro {
        height: 69vh;
    }

    .cart-bar__amount {
        right: 9px !important;
    }

    .area-cart {
        width: 100vw;
    }

    .area-cart-footer {
        width: 100vw !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .AddressBox {
        display: block;
    }

    .container-address {
        padding: 0;
        width: 100%;
    }

    .listaEnderecoContainer {
        max-height: 363px;
        min-height: 100px;
    }

    .listaEnderecoContainer.__lista-endereco {
        min-height: 100px;
        max-height: 314px;
    }

    .AddressNew {
        font-size: 16px;
    }

    .icon-addressNew {
        font-Size: 16px;
    }

    .address-cadastro {
        height: 69vh;
    }

    .cart-bar__amount {
        right: 18px !important;
    }

    .area-cart {
        width: 100vw;
    }

    .area-cart-footer {
        width: 100vw !important;
    }
}

@media (min-width: 768px) and (max-width: 1247px) {
    .context-card {
        position: fixed;
    }

    .context-card.context-card--visible {
        visibility: inherit;
    }

    .area-cart {
        width: 100vw !important;
    }

    .area-cart-footer {
        width: 100vw !important;
    }    
}

@media (min-width: 768px) and (max-width: 1023px) {
    .cart-bar__amount {
        right: 76px !important;
    }

    .area-cart {
        width: 100vw;
    }

    .area-cart-footer {
        width: 100vw !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container-address {
        padding: 0px 5px;
        width: 100%;
    }

    .listaEnderecoContainer {
        max-height: 364px;
        min-height: 100px;
    }

    .listaEnderecoContainer.__lista-endereco {
        min-height: 100px;
        max-height: 314px;
    }

    .AddressNew {
        font-size: 16px;
    }

    .icon-addressNew {
        font-Size: 16px;
    }

    .address-cadastro {
        height: 76vh;
    }

    .area-cart__line_button {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .cart-bar__amount {
        right: 119px !important;
    }
}

