/* Coração favorito */
/* body {
  display: flex;
  justify-content: center;
  margin: 0;
  height: 100vh;
} */
/* Icone Compartilhamento */
[id="share"] {
  position: relative;
  left: -100vw;
}

.share {
  color: #aab8c2;  
  cursor: pointer;
  font-size: 20px;
  align-self: center;
  float:right;
  transition: color 0.2s ease-in-out;
}

.share:hover {
  color: #677786;
}

.share::selection {
  color: none;
  background: transparent;
}

.share::moz-selection {
  color: none;
  background: transparent;
}

/* Icone Coracao */
.heart{
  position: relative;
  left: -100vw;
}

.a-normal{
    text-decoration: unset;
    color: unset;
}

@keyframes heart {0%, 17.5% {font-size: 0;}}
