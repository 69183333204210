.media-body {
    padding: 5px;
}

.bola {
    border-radius: 50%;
    display: inline-block;
    height: 45px;
    width: 45px;
    border: 1px solid #115893;
    background-color: #ffffff;    
}

.circulo {
	width: 57px;
	height: 57px;
	border-radius: 50%;
	overflow: hidden;
	float: left;	
    background-color: #ffffff;    
}

.circulo-pequeno {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 3px;
	float: left;    
    background-color: #ffffff;
    border: 1px solid #115893;     
    font-weight: bold;
    font-size: 18px;  
    color: #115893;
}