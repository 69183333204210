.modal-dialog {
    height: 100%;
    opacity: 1;
    transition: opacity 150ms ease-in-out;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;        
}

.modal-detail .modal-content {
    width: 649px;
    /* height: 570px;     */
    height: auto;  
    /* height: fit-content; */
}

.modal-detail.modal-header {
    height: 55px;
    padding: 10px;    
    text-align: center;
    text-transform: uppercase;
}

.modal-detail .modal-header .close {
    font-size: 43px;
    line-height: 18px;
    font-weight: 400;
    color: var(--color-primaria-thema);
}

.modal-header .icon-arrow {
    margin-left: -10px;
}

.icon-arrow {
    display: none;
    font-size: 35px;
    line-height: 38px;    
    font-weight: bold;
    color: var(--color-link-acao);
}

.product-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;    
}

.modal-header h4 {
    font-size: 16px !important;
    text-transform: uppercase;
    font-weight: 600;    
}

@media (max-width: 320px) {
    .modal-detail .modal-content {
        height: 100vh;  
    }
}

@media (min-width: 375px) and (max-width: 1023px) {
    .modal-detail .modal-content {
        height: 100vh;  
    }
}

@media (min-width: 360px) and (max-width: 360px) {
    .modal-detail .modal-content {
        height: 100vh;  
    }
}

@media (max-width: 767px) {
    .modal-dialog {
        margin: 0 !important;                
    }

    .modal-content {
        border: 0 !important;
        border-radius: 0 !important;
        box-shadow: unset !important;         
        height: 100vh;          
    }

    .modal-detail .modal-content {
        /* height: 100% !important; */
        width: 100% !important;
    }

    .modal-header h4 {
        font-size: 16px !important;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 6px;
    }

    .icon-arrow {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 768px) {
    .modal-dialog {
        margin: 0 !important;        
    }

    .modal-content {
        border: 0 !important;
        border-radius: 0 !important;
        box-shadow: unset !important;        
        height: 100vh;
    }

    .modal-detail .modal-content {
        /* height: 100% !important; */
        width: 154% !important;
    }

    .modal-header h4 {
        font-size: 16px !important;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 6px;
    }

    .icon-arrow {
        display: block !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .modal-dialog {
        margin-top: 0 !important;        
    }

    .modal-detail .modal-content {
        height: 100vh;  
    }
}

